import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { Context } from ".."
import {StorageKeys} from "../core/utils/defaults"

import styles from "../styles/LangSwitcher.module.scss"

interface LangSwProps {
  theme?: "light" | "dark"
}

const LangSwitcher: FC<LangSwProps> = ({
  theme = "light"
}) => {

  const { store } = useContext(Context)

  const [actualLang, setActualLang] = useState("ru")
  const [nextLang, setNextLang] = useState("eg")
  const [lang, setLang] = useState("ru")

  const setLocale = (val: string) => {
    store.setLocale(val)
    localStorage.setItem(StorageKeys.LOCALE, val)
  }

  const switchLang = () => {
    const actual = document.getElementById("actual")
    const next = document.getElementById("next")

    if (actual != null && next != null) {
      if (store.locale == "ru-RU") {
        actual.style.left = "-32px"
        next.style.left = "0px"
        next.style.fontSize = "20px"
        next.style.opacity = "1"
  
        setTimeout(() => {
          actual.style.transition = "none"
          setTimeout(() => {
            actual.style.left = "62px"
            setLocale("en-EN")
            setTimeout(() => {
              actual.style.transition = "all 0.2s ease"
              actual.style.fontSize = "15px"
              actual.style.opacity = "0.5"
              setTimeout(() => {
                actual.style.left = "32px"
              }, 50);
            }, 50);
          }, 50);
        }, 50);
      } else if (store.locale == "en-EN") {
        next.style.left = "-32px"
        actual.style.left = "0px"
        actual.style.fontSize = "20px"
        actual.style.opacity = "1"
  
        setTimeout(() => {
          next.style.transition = "none"
          setTimeout(() => {
            next.style.left = "62px"
            setLocale("ru-RU")
            setTimeout(() => {
              next.style.transition = "all 0.2s ease"
              
              setTimeout(() => {
                next.style.fontSize = "15px"
                next.style.opacity = "0.5"
                next.style.left = "32px"
              }, 50);
            }, 50);
          }, 50);
        }, 50);
      }
      
    }
  }

  useEffect(() => {
    console.log(store.locale, store.locales)
  }, [])

  return (
    <>
      <div className={styles.lang_switcher + " " + (theme == "light" ? styles.light : styles.dark)}>
        <div className={styles.langs}>
          <div className={store.locale == "ru-RU" ? styles.active : ""} onClick={() => setLocale("ru-RU")}>RU</div>
          <div className={styles.divider}></div>
          <div className={store.locale == "en-EN" ? styles.active : ""} onClick={() => setLocale("en-EN")}>EN</div>
        </div>
      </div>
    </>
  )
}

export default observer(LangSwitcher)