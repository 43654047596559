export const LOCALES = {
  ENGLISH: 'en-EN',
  RUSSIAN: 'ru-RU',
  FRANCE: 'fr-FR'
}

export enum LOCALES_ENUM {
  ENGLISH = 'en-EN',
  RUSSIAN = 'ru-RU',
  FRANCE = 'fr-FR'
}