import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import {useLocation} from "react-router-dom"
import { Context } from "../.."

import styles from "../../styles/ManagerButton.module.scss"

const ManagerButton: FC = () => {
  const { store } = useContext(Context)
  const location = useLocation()

  const navTo = () => {
    let href = ""

    if (location.pathname == "/") {
      href = "/admin/content"
    }

    if (location.pathname == "/franchise") {
      href = "/admin/franchise"
    }

    if (location.pathname.includes("/loyalty")) {
      href = "/admin/loyalty"
    }

    if (location.pathname.includes("/blog")) {
      href = "/admin/blog"
    }

    if (
      location.pathname.includes("/branch") ||
      location.pathname.includes("/b/") ||
      location.pathname.includes("/barbershop")
    ) {
      href = "/admin/branches"
    }

    if (location.pathname.includes("/b/")) {
      href = "/admin/branches"
    }

    window.open(href, "_blank")
  }

  return (
    <>
      <div className={styles.manager_button} onClick={navTo}><button><i className="fas fa-cog"></i></button></div>
    </>
  )
}

export default observer(ManagerButton)