import { makeAutoObservable } from "mobx";
import {IXProduct} from "../models/Product";
import {StorageKeys} from "../utils/defaults";

export default class LoyaltyStoreX {

  storeList: IXProduct[] = []
  storeSubsList: IXProduct[] = []
  bestList: IXProduct[] = []
  bestListIx: number = 0
  bestListLoading: boolean = false

  totalSale: number = 40

  storiesTab: string = "inview"

  bonuses: number = 0
  bonusCard: any | null = null
  bonusHistory: any[] = []

  isAccountLoaded: boolean = false

  visits: any[] = []

  loadingTimes: number = 1

  lastVisitDate: string = ""

  monthsStats: any | null = null

  catsList: any[] = []
  wishesList: any[] = []
  selectedCat: any | null = null
  isAddCatVisible: boolean = false
  isEdtCatVisible: boolean = false
  isDelCatVisible: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsAddCatVisible(val: boolean) {
    this.isAddCatVisible = val
  }

  setIsEdtCatVisible(val: boolean) {
    this.isEdtCatVisible = val
  }

  setIsDelCatVisible(val: boolean) {
    this.isDelCatVisible = val
  }

  setCatsList(val: any[]) {
    this.catsList = val
  }

  setWishesList(val: any[]) {
    this.wishesList = val
  }

  setSelectedCat(val: any) {
    this.selectedCat = val
  }

  setMonthsStats(val: any) {
    this.monthsStats = val
    localStorage.setItem(StorageKeys.MONTH_STATS, JSON.stringify(val))
  }

  setLastVisitDate(val: string) {
    this.lastVisitDate = val
  }

  setLoadingTimes(times: number) {
    this.loadingTimes = times
  }

  setVisits(val: any[]) {
    this.visits = val
  }

  setIsAccountLoaded(val: boolean) {
    this.isAccountLoaded = val
  }

  setBonusCard(val: any) {
    this.bonusCard = val
  }

  setBonuses(val: number) {
    this.bonuses = val
  }

  setBonusHistory(val: any[]) {
    this.bonusHistory = val
  }

  setStoriesTab(val: string) {
    this.storiesTab = val
  }

  setStoreList(val: IXProduct[]) {
    this.storeList = val
  }

  setStoreSubsList(val: IXProduct[]) {
    this.storeSubsList = val
  }

  setBestList(val: IXProduct[]) {
    this.bestList = val
  }

  setBestListIx(val: number) {
    this.bestListIx = val
  }

  setBestListLoading(val: boolean) {
    this.bestListLoading = val
  }

}