import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Context } from "../../../.."
import { ApiService } from "../../../../core/api/api"
import useWindowDimensions from "../../../../core/hooks/useWindowDimensions"
import { isNullOrUndefined } from "../../../../core/utils/utils"

import styles from "../../../../styles/TicketCardPage.module.scss"

const TicketCardPage: FC = () => {

  const { store, adminStore } = useContext(Context)

  const navigate = useNavigate()
  const params: any = useParams()
  
  const { width } = useWindowDimensions()

  const [user, setUser] = useState(null as any | null)
  const [userTicket, setUserTicket] = useState(null as any | null)
  const [userTicketPdf, setUserTicketPdf] = useState("")

  const fetchTicket = async (id: number) => {
    const res = await ApiService.events.getTicket(id)

    console.log(res)

    setUser(res.data.user)
    setUserTicket(res.data.html)
    setUserTicketPdf(res.data.pdf_url)
  }

  useEffect(() => {

    setTimeout(() => {
      console.log("params", params)
      if (!isNullOrUndefined(params.ticket_id)) {
        fetchTicket(params.ticket_id)
      } else {
        navigate("/admin/404")
      }
    }, 300)
    
  }, [])

  return (
    user != null ? <>
      <div className={styles.ticket_card}>
        <div className={styles.wrapper + " container"}>
          <div className="row">
            <div className={"col-12 col-md-8 offset-md-2 " + styles.container}>
              <h1>{user.name}</h1>
              <h2>{user.email}</h2>

              <div className={styles.ticket_num}>{user.ticket_id}</div>
            </div>
          </div>
        </div>
      </div>
    </> : <></>
  )
}

export default observer(TicketCardPage)