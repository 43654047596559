import { makeAutoObservable } from "mobx";

export default class AdminStore {

  adminClientsType: string = ""
  adminTabIndex: number = 0
  selectedColumnTag: number = 0

  selectedProduct: any | null = null
  selectedClient: any | null = null

  searchText: string = ""
  searchTextA: string = ""
  searchProdText: string = ""

  usersList: any[] = []
  selectedUsr: any | null = null

  productsList: any[] = []
  selectedProd: any | null = null

  selectedCategory: any | null = null

  selectedPostId: number = 0

  isColorPicking: boolean = false

  searchTranslate: string = ""

  eventsList: any[] = []

  selectedPromo: any = null

  isDelPromoVisible: boolean = false
  isAddPromoVisible: boolean = false
  isAddPromoCodeVisible: boolean = false

  isAddStoryVisible: boolean = false
  isEdtStoryVisible: boolean = false
  isDelStoryVisible: boolean = false

  isAddCatVisible: boolean = false
  isDelCatVisible: boolean = false

  storeTab: number = 0

  orderSearch: string = ""

  vkVideos: any[] = []
  selectedVKVideo: any = null

  constructor() {
    makeAutoObservable(this)
  }

  setSelectedVKVideo(val: any) {
    this.selectedVKVideo = val
  }

  setVKVideos(val: any[]) {
    this.vkVideos = val
  }

  setOrderSearch(val: string) {
    this.orderSearch = val
  }

  setIsDelCatVisible(val: boolean) {
    this.isDelCatVisible = val
  }

  setIsAddCatVisible(val: boolean) {
    this.isAddCatVisible = val
  }

  setSelectedCategory(val: any) {
    this.selectedCategory = val
  }

  setStoreTab(val: number) {
    this.storeTab = val
  }

  setSelectedPromo(val: any) {
    this.selectedPromo = val
  }

  setIsDelPromoVisible(val: boolean) {
    this.isDelPromoVisible = val
  }

  setIsAddPromoVisible(val: boolean) {
    this.isAddPromoVisible = val
  }

  setIsAddPromoCodeVisible(val: boolean) {
    this.isAddPromoCodeVisible = val
  }

  setIsAddStoryVisible(val: boolean) {
    this.isAddStoryVisible = val
  }

  setIsEdtStoryVisible(val: boolean) {
    this.isEdtStoryVisible = val
  }

  setIsDelStoryVisible(val: boolean) {
    this.isDelStoryVisible = val
  }

  setEventsList(val: any[]) {
    this.eventsList = val
  }
  
  setSearchTranslate(val: string) {
    this.searchTranslate = val
  }

  setIsColorPicking(value: boolean) {
    this.isColorPicking = value
  }

  setSelectedPostId(val: number) {
    this.selectedPostId = val
  }

  setSelectedProd(val: any) {
    this.selectedProd = val
  }

  setProductsList(val: any[]) {
    this.productsList = val
  }

  setSelectedUsr(val: any) {
    this.selectedUsr = val
  }

  setUsersList(val: any[]) {
    this.usersList = val
  }

  setSearchProdText(val: string) {
    this.searchProdText = val
  }

  setSearchTextA(val: string) {
    this.searchTextA = val
  }
  
  setSearchText(val: string) {
    this.searchText = val
  }

  setSelectedColumnTag(index: number) {
    this.selectedColumnTag = index
  }

  setAdminTabIndex(index: number) {
    this.adminTabIndex = index
  }

  setAdminClientsType(val: string) {
    this.adminClientsType = val
  }

  setSelectedProduct(val: any) {
    this.selectedProduct = val
  }

  setSelectedClient(val: any) {
    this.selectedClient = val
  }

}