import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { API_URL, StorageKeys } from "../utils/defaults"
import { isNullOrUndefined } from "../utils/utils"

const $api = axios.create({
  baseURL: API_URL
})

$api.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers!["Authorization"] = `Bearer ${localStorage.getItem(StorageKeys.TOKEN)}`
  return config
})

$api.interceptors.response.use((response: AxiosResponse) => {
  return response
}, async (error: any) => {
  console.log("INTERCEPT", error.response.data.message)
  if (!isNullOrUndefined(error.response.data.message)) {
    if (error.response.data.message == "Пользователь не авторизован") {
      localStorage.clear()
      // window.location.reload()
      setTimeout(() => {
        window.location.href = "/admin/signin"
      }, 1000)
    }
  }
  return error
})

export default $api