import {message} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Context } from "../.."
import { ApiService } from "../../core/api/api"
import {StorageKeys} from "../../core/utils/defaults"

import styles from "../../styles/LockScreen.module.scss"

const AdminLockscreen: FC = () => {
  const { store } = useContext(Context)
  const navigate = useNavigate()

  const [password, setPassword] = useState("")

  const signIn = async (event: any) => {
    if (password.length <= 3) {
      message.error("Введите корректный пароль!")
      return
    }

    const res = await ApiService.users.signIn({login: store.userData.email, email: store.userData.email, password})

    if (res.data.state == 1) {
      store.setupAuth(res.data.token, res.data.user)
      store.setLockScreen(false)

      localStorage.removeItem(StorageKeys.LOCK_SCREEN)
    } else {
      message.error(res.data.message)
    }
  }

  useEffect(() => {

  }, [])

  return (
    <>
      <div className={styles.lockscreen}>
        <div className={styles.page_blink + " " + styles.blue + " " + styles.toped}></div>
        <div className={styles.page_blink + " " + styles.right + " " + styles.toped3}></div>

        <div className={styles.ls_wrap}>
          <div className={styles.ls_wrap_container}>
            <div className={styles.ls_title}>Разблокировать экран</div>
            <div className={styles.ls_form}>
              <div className={styles.ls_form_user}>
                <div className={styles.ls_form_user__ava} style={{backgroundImage: `url(${store.userData.avatar})`}}></div>
                <div className={styles.ls_form_user__data}>
                  <div>{store.userData.name}</div>
                  <div>{store.userData.email}</div>
                </div>
              </div>
              <div className={styles.ls_form_item}>
                <input type="password" placeholder="*********" value={password} onChange={(event) => setPassword(prev => prev = event.target.value)} />
              </div>
              <div className={styles.ls_form_action}>
                <button onClick={signIn}>Войти</button>
              </div>
            </div>
            <div className={styles.ls_copys}><b>TOPGUN</b> CRM</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(AdminLockscreen)