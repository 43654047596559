export enum StorageKey {
  USER = "topgun_user_data",
  AUTHED = "topgun_user_authed",
  TOKEN = "nx_adsnetwork_token",
  FS_IMAGE = "topgun_fs_image",
  FS_VIDEO = "topgun_fs_video",
  FS_VIDEOS = "topgun_fs_videos",
}

export enum ControllerName {
  USERS = "/users",
  VIDEOS = "/videos",
  PRICES = "/prices",
  CORE = "/core",
  TV = "/tvsocket"
}