import React, {createContext} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Store from './core/store'
import { BrowserRouter } from 'react-router-dom'
import LoyaltyStoreX from './core/store/loyalty'
import AdminStore from './core/store/admin'
import AdsNetStore from './core/store/adsnet'

interface State {
  store: Store
  adminStore: AdminStore
  loyaltyStore: LoyaltyStoreX
  adsnetStore: AdsNetStore
}

const store = new Store()
const adminStore = new AdminStore()
const loyaltyStore = new LoyaltyStoreX()
const adsnetStore = new AdsNetStore()

export const Context = createContext<State>({ store, loyaltyStore, adminStore, adsnetStore })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Context.Provider value={{ store, loyaltyStore, adminStore, adsnetStore }}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Context.Provider>
);

reportWebVitals();
